/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';

// import 'jquery/dist/jquery.min.js'
// import 'popper.js/dist/popper.min'
//import 'bootstrap/dist/js/bootstrap.min.js';
import { silentAuth } from './src/utils/auth';

class SessionCheck extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      loading: true
    };
  }

  handleCheckSession = () => {
    this.setState( { loading: false } );
  };

  componentDidMount() {
    silentAuth( this.handleCheckSession );
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    );
  }
}

export const wrapRootElement = ( { element } ) => {
  return <SessionCheck>{element}</SessionCheck>;
};
